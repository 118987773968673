.sc-modal {
  height: calc(90vh - 2rem);

  .sc-card {
    // height: 100%;
    display: flex;
    flex-direction: column;

    .sc-card-body {
      flex-grow: 10;
      overflow: auto;
      height: auto;
    }
  }
}
