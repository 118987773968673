#app-map {
  height: 100%;
}

.app {
  position: relative;
  height: 100%;
}

.mapboxgl-popup-content {
  width: 20rem;

  @media only screen and (min-width: 600px) {
    width: 40rem;
  }

  .sc-card-header {
    padding: var(--padding-sm) 0 !important;

    h5 {
      text-decoration: underline;
      margin: 0 !important;
      cursor: pointer;
    }
  }

  .sc-card-body {
    display: flex;
    flex-direction: column;
    // height: auto;

    @media only screen and (min-width: 600px) {
      flex-direction: row;
      justify-content: space-between;
    }

    & > div {
      width: 100%;

      @media only screen and (min-width: 600px) {
        width: calc(50% - var(--padding-md) / 2);
      }

      &:nth-of-type(2) {
        margin-top: var(--padding-sm);

        @media only screen and (min-width: 600px) {
          margin-top: 0;
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      cursor: pointer;
    }
  }

  .sc-card-footer {
    font-size: 1.1rem;
  }
}
