/* ------------- Popup ------------- */

.mapboxgl-popup {
  font-family: inherit !important;
  max-width: none !important;
}

.mapboxgl-popup-content {
  /* width: 22rem !important; */
  padding: 0 !important;
  height: auto;
  border-radius: var(--border-radius) !important;
  box-shadow: var(--box-shadow-md) !important;
  background-color: var(--color-lighter) !important;
}

/* ------------- Popup tip ------------- */

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-bottom-right .mapboxgl-popup-tip {
  border-top-color: var(--color-lighter) !important;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-left .mapboxgl-popup-tip,
.mapboxgl-popup-anchor-top-right .mapboxgl-popup-tip {
  border-bottom-color: var(--color-lighter) !important;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: var(--color-lighter) !important;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: var(--color-lighter) !important;
}

/* ------------- Control ------------- */

.mapboxgl-ctrl-group {
  border-radius: var(--border-radius) !important;
  border: var(--border-thickness) solid var(--color-primary-light) !important;
  box-shadow: var(--box-shadow-md) !important;
}

.mapboxgl-ctrl-top-left .mapboxgl-ctrl-group {
  margin: var(--padding-md) 0 0 var(--padding-md) !important;
}

.mapboxgl-ctrl-top-right .mapboxgl-ctrl-group {
  margin: var(--padding-md) var(--padding-md) 0 0 !important;
}

.mapboxgl-ctrl-bottom-left .mapboxgl-ctrl-group {
  margin: 0 0 var(--padding-md) var(--padding-md) !important;
}

.mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-group {
  margin: 0 var(--padding-md) var(--padding-md) 0 !important;
}

/* ------------- Control buttons ------------- */

.mapboxgl-ctrl-group {
  overflow: hidden !important;
  background-color: var(--color-lighter) !important;
}

.mapboxgl-ctrl-group button + button {
  border-top: var(--border-thickness) solid var(--fade-grey-md) !important;
}

.mapboxgl-ctrl-group button {
  position: relative !important;
  width: var(--icon-lg) !important;
  height: var(--icon-lg) !important;
}

.mapboxgl-ctrl-group button:hover {
  background-color: var(--color-light) !important;
}

.mapboxgl-ctrl-group button:focus {
  box-shadow: none !important;
}

.mapboxgl-ctrl-group button span {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  mask-position: center !important;
  mask-size: 50% !important;
  mask-repeat: no-repeat !important;
  background-color: var(--color-dark) !important;
  background-image: none !important;
  transition: var(--transition-md) !important;
}

.mapboxgl-ctrl-group button:hover span,
.mapboxgl-ctrl-group button:focus span {
  background-color: var(--color-secondary-dark) !important;
}

.mapboxgl-ctrl-group button.mapboxgl-ctrl-geolocate span {
  mask-image: var(--icon-geolocation);
}

.mapboxgl-ctrl-group button.mapboxgl-ctrl-fullscreen span {
  mask-image: var(--icon-fullscreen);
}

.mapboxgl-ctrl-group button.mapboxgl-ctrl-zoom-in span {
  mask-image: var(--icon-zoom-in);
}

.mapboxgl-ctrl-group button.mapboxgl-ctrl-zoom-out span {
  mask-image: var(--icon-zoom-out);
}

.mapboxgl-ctrl-group button.mapboxgl-ctrl-compass span {
  mask-image: var(--icon-compass);
}
